import React, { Fragment } from 'react';
// import { Link } from 'gatsby';
import Layout from '../components/layoutProfile';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
// import 'react-vertical-timeline-component/style.min.css';
import mng from '../images/ob/interview_sowa.jpg';
import mukai from '../images/ob_mukai.jpg';
import mukaiImg1 from '../images/ob/mukaiImg1.jpg';
import mukaiImg2 from '../images/ob/mukaiImg2.jpg';
// import * as ROUTES from '../src/constants/routes';

// <div className="ob-top-align">
// <img className="ob-top-info" alt="sakai" src={mukaiTop} />
// <div>2010年卒　向井和希</div>
// </div>
class MukaiPage extends React.Component {

  render() {
    return (
      <Fragment>
      <title>地方部員インタビュー　向井和希|國學院大學久我山高等学校　バスケットボール部</title>
      <h2 className="content-title-black-ob">OBインタビュー</h2>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: 'rgb(24, 23, 51)', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
        <h4 className="vertical-timeline-element-subtitle">3年 楚和</h4>
          <div className="mng-text">
          お忙しい中、インタビューに応じて下さり、ありがとうございます。
久我山高校バスケ部３年デジタル運営チーム代表の楚和と申します。本日は、どうぞよろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={mukai} />}
        >
          <h4 className="vertical-timeline-element-subtitle">OB 向井和希</h4>
          <div className="ob-text">
            よろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
            さっそくですが、簡単な自己紹介からお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={mukai} />}
        >
          <div className="ob-text">
          わかりました。向井和希と言います。自分は２０１３年に國學院久我山高校を卒業し、その後、同志社大学に進学しました。大学卒業後は、大手銀行の京都支社に就職し、現在は、法人営業部に所属しています。法人営業部では、様々な会社へのお金の貸し借りなどの金融面でのサポートをしています。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
ありがとうございます。それでは、早速、1つ目の質問に入らせてもらいます。國學院久我山高校の入学理由を教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={mukai} />}
        >
          <div className="ob-text">
          そうですね。きっかけとしては、幼馴染みに久我山高校バスケ部の出身者がいて、その人に練習会があることを聞き参加したことがきっかけですね。実は、他校からも推薦でのお誘いがありましたが、どの学校もスポーツ重視でした。
          その中で、國學院久我山高校は文武両道を掲げており、そこに入学することで自分の将来の道が開けそうだと思い入学を決意しました。
        </div>
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。実際に國學院久我山高校に入学して、よかったことを教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={mukai} />}
        >
          <div className="ob-text">
          そうですね。スポーツ推薦のクラスも一般クラスと同じテストを行うなど、勉強面もやはり重視していました。その中で、部活もやっていくので、大変な日々ではありましたが、妥協せずに通学時間に勉強をしたり、少し早起きして勉強の時間を確保したり、工夫をするようになりました。部活面では、練習時間が短いので、どこに重きを置くのか考え、またどうやって勝つのか、など自分たちで思考する力がついたと思います。共通して言えることは、言い訳せず、頑張る姿勢が身についたことですね。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          勉強面でも部活面でも「工夫する力」、そして「言い訳せずに頑張る姿勢」というものが、身についたんですね。僕も向井さんを見習って言い訳せず今後も頑張ろうと思いました！ちなみに、向井さんが現役だった頃の久我山高校バスケ部の雰囲気は、どのような感じだったのでしょうか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={mukai} />}
        >
          <div className="ob-text">
          入学前はとにかく怖かったです。（笑）公立中でワンマンチームでバスケをやっていた中学時代に比べ、久我山高校バスケ部では、全員がそれぞれ役割を持っており、またハイレベルなバスケに不安を感じていました。入学後も、変わらず厳しい環境下でしたが、妥協せず切磋琢磨していく感じでした。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={mukai} />}
        >
          <div className="ob-text">
          <img className="obImgMukai" alt="sakai" src={mukaiImg1} />
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます！自分も一貫生上がりで練習会に参加したのですが、周りのレベルが高く、ついていけるのかと不安でした。でも、周りのレベルが高いと自分も追いつき追い越せの状態になり、すごく成長できたと思います。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={mukai} />}
        >
          <div className="ob-text">
          そうですよね！
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          では、次の質問に移らせて頂きます。酒井監督の印象について教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={mukai} />}
        >
          <div className="ob-text">
          楚和君はどう？(笑)
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          やはり最初に思い浮かぶのは、自分の信条を貫き、みんなを引っ張って行くような熱い姿ですね。
          付け加えて、時代の変化に対応する柔軟力があり、最近では様々な取り組みを行なっています。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={mukai} />}
        >
          <div className="ob-text">
          へー！　最近だとどんな取り組みをしてるの？
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          例えば、選手それぞれに体調を管理するアプリを導入していたり、作戦版をアナログからデジタルへと変革させたりと、最新技術も取り入れてますね。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={mukai} />}
        >
          <div className="ob-text">
          すごいですね！（笑）様々な取り組みを行なっているのは意外でしたが、熱い教師像は共感できます。担任であり、監督であった酒井先生は、やはり熱血直球の先生で、裏表もなく裏切られない一種の安心感がありましたね。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          やはり、あの熱さは昔からなんですね。(笑)それでは次に、全国大会を目指す久我山高校バスケ部において、様々な苦労をしてきたと思いますが、向井さんは具体的にどのような苦労を経験しましたか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={mukai} />}
        >
          <div className="ob-text">
          端的に言うなら、プレッシャーがものすごくありました。なぜかと言うと、上の代も下の代もスター性のある選手がいました。なので、上下ともにプレッシャーが強く苦労したのを覚えています。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          上下からのプレッシャーがある中で、向井さんはどのように乗り越えたのでしょうか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={mukai} />}
        >
          <div className="ob-text">
          それは「素直」になることです。やるしかないと決意し、できないこと、わからないことは「素直」に逃げずに、監督や先輩に聞き、自分の成長に繋げていきました。その結果、ゆっくりですが確実に成長でき、インターハイ出場など結果が出せました。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。自分も、夏に一回スランプみたいなものになってしまい、苦労しました。そこで、同級生や先輩に素直に耳を傾ける事で、なんとかスランプから抜けました！
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={mukai} />}
        >
          <div className="ob-text">
やっぱり愚直に続けていくが、肝になりますね。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          本当にそうですね。インターハイ出場とありましたが、やはり出場したことは忘れられない思い出ですか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={mukai} />}
        >
          <div className="ob-text">
          もちろん大事な思い出です。先ほど述べた通り、プレッシャーなどで押し潰されそうになりながら、なんとか出場できたので凄く嬉しかったです。当時は、泣きながら仲間と抱き合って喜んでいましたね。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。やはり全国大会出場は格別ですね。それでは、最後の質問に移らせていただきます。久我山高校での学校生活で、社会人になった今でも活きている事はどのような事がありますか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={mukai} />}
        >
          <div className="ob-text">
          入学してよかった事にも挙げたとおり、「言い訳をしなくなった」ことです。そのことで、プラス思考へと切り替わり、仕事でも生きています。それに加えて、「当たり前のことを当たり前にできる」ようになったことですね。やはり挨拶などは、一般常識と言いますが、実際に社会に出てみると、できない人は多いです。なので、挨拶ができると結構有利になると思います。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={mukai} />}
        >
          <div className="ob-text">
          <img className="obImgMukai" alt="sakai" src={mukaiImg2} />
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。やはり挨拶などは、バスケ部でも口すっぱく言われているので、向井さんの話を聞いて納得しました。それでは、最後に國學院久我山高校、そしてバスケ部に入学希望がある、中学生又は保護者にメッセージをお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={mukai} />}
        >
          <div className="ob-text">
          はい。
          久我山高校は、やはり楽なところではなく厳しさもある学校です。しかし、この経験が、社会に出た時に有利になり、人間的にも成長させてくれます。だからこそ、是非とも入学してもらい頑張ってもらいたいです。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
本日はお忙しい中本当にありがとうございました。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={mukai} />}
        >
          <div className="ob-text">
こちらこそありがとうございました。
        </div>
        </VerticalTimelineElement>

      </VerticalTimeline>
    </Fragment>
);
}
}

export default () => (
  <Layout>
  <MukaiPage />
  </Layout>
);
